import React from 'react';
import Home from './pages/Common/Home'
import Login from './pages/Common/Login';
import {BrowserRouter as Router, Route, Navigate, Routes} from 'react-router-dom';
import { Global } from '@emotion/react';

import './App.css';
import Register from './pages/Common/Register';
import EnrollYourBusiness from './pages/Common/EnrollYourBusiness';
import DeleteYourBusiness from './pages/Common/DeleteYourBusiness';
import ForgotPasswordEmail from './pages/Common/ForgotPasswordEmail';
import ResetPasswordEmail from './pages/Common/ResetPasswordEmail';
import SuperAdminHomepage from './pages/SuperAdminView/SuperAdminHomepage';
import BusinessOwnerHomepage from './pages/BusinessOwnerView/BusinessOwnerHomepage';
import BusinessMemberHomepage from './pages/BusinessMemberView/BusinessMemberHomepage';
import ViewBusinesses from './components/SuperAdminView/BusinessManagement/ViewBusinesses';
import CreateFeature from './components/SuperAdminView/FeaturesManagement/CreateFeature';
import CreateBusiness from './components/SuperAdminView/BusinessManagement/CreateBusiness';
import ManageEnrolment from './components/SuperAdminView/UserManagement/ManageEnrolment'
import InviteUser from './components/SuperAdminView/UserManagement/InviteUser';
import PendingUsers from './components/SuperAdminView/UserManagement/PendingUsers';
import ActiveUsers from './components/SuperAdminView/UserManagement/ActiveUsers';
import ViewSales from './components/SuperAdminView/ViewSales/ViewSales';
import ViewSelectedBusinessSales from './components/SuperAdminView/ViewSales/ViewSelectedBusinessSales';
import ViewSelectedBusinessMemberSales from './components/SuperAdminView/ViewSales/ViewSelectedBusinessMemberSales';
import ViewSelectedOrder from './components/SuperAdminView/ViewSales/ViewSelectedOrder';
import ModifySelectedBusiness from './components/SuperAdminView/BusinessManagement/ModifySelectedBusiness';
import CreateEnrolledUserBusiness from './components/SuperAdminView/BusinessManagement/CreateEnrolledUserBusiness'
import ProtectedAdminRoute from './utils/ProtectedAdminRoute';
import ProtectedOwnerRoute from './utils/ProtectedOwnerRoute';
import ProtectedMemberRoute from './utils/ProtectedMemberRoute';
import ViewFeatures from './components/SuperAdminView/FeaturesManagement/ViewFeatures';
import ModifySelectedFeature from './components/SuperAdminView/FeaturesManagement/ModifySelectedFeature';
import { globalStyles } from './pages/Common/GlobalStyles';
import { ThemeProvider } from '@mui/material/styles';
import theme from "./themes/theme";

import TermsOfService from './pages/Common/policies/TermsOfService';
import PrivacyPolicy from './pages/Common/policies/PrivacyPolicy';
import DataPolicy from './pages/Common/policies/DataPolicy';
import Disclaimer from './pages/Common/policies/Disclaimer';
import AppPermissions from './pages/Common/policies/AppPermissions';

import TermsOfServiceMobile from './pages/Common/policies/TermsOfServiceMobile';
import PrivacyPolicyMobile from './pages/Common/policies/PrivacyPolicyMobile';
import DataPolicyMobile from './pages/Common/policies/DataPolicyMobile';
import DisclaimerMobile from './pages/Common/policies/DisclaimerMobile';
import AppPermissionsMobile from './pages/Common/policies/AppPermissionsMobile';


function App() {


  return (
    <>
      {/* Apply global styles */}
      <Global styles={globalStyles} />
        <ThemeProvider theme={theme}>
          <Router>
            <main>
                <Routes>

                    <Route key="home" path="/home" element={<Home />} />
                    <Route key="login" path="/login" element={<Login />} />
                    <Route key="register" path="/register" element={<Register />} />
                    <Route key="enroll-your-business" path="/enroll-your-business" element={<EnrollYourBusiness />} />
                    <Route key="delete-your-business" path="/delete-your-business" element={<DeleteYourBusiness />} />
                    <Route key="forgot-password-email" path="/forgot-password-email" element={<ForgotPasswordEmail />} />
                    <Route key="reset-password-email" path="/reset-password-email" element={<ResetPasswordEmail />} />
                    <Route key="default_home" path="*" element={<Home />} />
                    <Route key="terms-of-service" path="/terms-of-service" element={<TermsOfService />} />
                    <Route key="privacy-policy" path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route key="data-policy" path="/data-policy" element={<DataPolicy />} />
                    <Route key="disclaime" path="/disclaimer" element={<Disclaimer />} />
                    <Route key="app-permissions" path="/app-permissions" element={<AppPermissions />} />

                    {/* Mobile Privacy Policy */}
                    <Route key="terms-of-service-mobile" path="/terms-of-service-mobile" element={<TermsOfServiceMobile />} />
                    <Route key="privacy-policy-mobile" path="/privacy-policy-mobile" element={<PrivacyPolicyMobile />} />
                    <Route key="data-policy-mobile" path="/data-policy-mobile" element={<DataPolicyMobile />} />
                    <Route key="disclaime-mobile" path="/disclaimer-mobile" element={<DisclaimerMobile />} />
                    <Route key="app-permissions-mobile" path="/app-permissions-mobile" element={<AppPermissionsMobile />} />


                    <Route key="default_home" path="/" element={<Home />} />
                    <Route key="default_home-general" path="*" element={<Navigate to="/" replace />} />

                    {/* <!-- SuperAdmin routes --> */}
                    <Route key="admin-default-home" path="/" element={<ProtectedAdminRoute />}>
                      <Route key="admin-home" path="admin/home" element={<SuperAdminHomepage />} />
                      <Route key="admin-create-feature" path="admin/create-feature" element={<CreateFeature />} />
                      <Route key="admin-view-modify-and-delete-feature" path="admin/view-modify-and-delete-feature" element={<ViewFeatures />} />
                      <Route key="admin-modify-feature" path="admin/modify-feature" element={<ModifySelectedFeature />} />
                      <Route key="admin-admin-create-business" path="admin/create-business" element={<CreateBusiness />} />
                      <Route key="admin-view-modify-and-delete-business" path="admin/view-modify-and-delete-business" element={<ViewBusinesses role={'SUPER_ADMIN'} />} />
                      <Route key="admin-modify-business" path="admin/modify-business" element={<ModifySelectedBusiness role={'SUPER_ADMIN'} />} />
                      
                      <Route key="admin-create-enrolled-user-business" path="admin/create-enrolled-user-business" element={<CreateEnrolledUserBusiness role={'SUPER_ADMIN'} />} />
                      <Route key="admin-manage-enrolment" path="admin/manage-enrolment" element={<ManageEnrolment role={'SUPER_ADMIN'} />} />

                      <Route key="admin-invite-user" path="admin/invite-user" element={<InviteUser role={'SUPER_ADMIN'} />} />
                      <Route key="admin-pending-users" path="admin/pending-users" element={<PendingUsers role={'SUPER_ADMIN'} />} />
                      <Route key="admin-active-users" path="admin/active-users" element={<ActiveUsers role={'SUPER_ADMIN'} />} />
                      <Route key="admin-view-sales" path="admin/view-sales" element={<ViewSales role={'SUPER_ADMIN'} />} />
                      <Route key="admin-view-selected-business-sales" path="admin/view-selected-business-sales" element={<ViewSelectedBusinessSales role={'SUPER_ADMIN'} />} />
                      <Route key="admin-view-selected-business-member-sales" path="admin/view-selected-business-member-sales" element={<ViewSelectedBusinessMemberSales role={'SUPER_ADMIN'} />} />
                      <Route key="admin-view-selected-order" path="admin/view-selected-order" element={<ViewSelectedOrder role={'SUPER_ADMIN'} />} />
                    </Route>

                    {/* <!-- BusinessOwner routes --> */}
                    <Route key="owner-default_home" path="/" element={<ProtectedOwnerRoute />}>
                      <Route key="owner-home" path="owner/home" element={<BusinessOwnerHomepage />} />
                      <Route key="owner-view-modify-and-delete-business" path="owner/view-modify-and-delete-business" element={<ViewBusinesses role={'BUSINESS_OWNER'}/>} />
                      <Route key="owner-modify-business" path="owner/modify-business" element={<ModifySelectedBusiness role={'BUSINESS_OWNER'} />} />
                      <Route key="owner-invite-user" path="owner/invite-user" element={<InviteUser role={'BUSINESS_OWNER'} />} />
                      <Route key="owner-pending-users" path="owner/pending-users" element={<PendingUsers role={'BUSINESS_OWNER'} />} />
                      <Route key="owner-active-users" path="owner/active-users" element={<ActiveUsers role={'BUSINESS_OWNER'} />} />
                      <Route key="owner-view-sales" path="owner/view-sales" element={<ViewSales role={'BUSINESS_OWNER'} />} />
                      <Route key="owner-view-selected-business-sales" path="owner/view-selected-business-sales" element={<ViewSelectedBusinessSales role={'BUSINESS_OWNER'} />} />
                      <Route key="owner-view-selected-business-member-sales" path="owner/view-selected-business-member-sales" element={<ViewSelectedBusinessMemberSales role={'BUSINESS_OWNER'} />} />
                      <Route key="owner-view-selected-order" path="owner/view-selected-order" element={<ViewSelectedOrder role={'BUSINESS_OWNER'} />} />
                    </Route>

                    {/* <!-- BusinessMember routes --> */}
                    <Route key="memner-default_home" path="/" element={<ProtectedMemberRoute />}>
                      <Route key="member-home" path="member/home" element={<BusinessMemberHomepage />} />
                      <Route key="member-view-sales" path="member/view-sales" element={<ViewSales role={'BUSINESS_MEMBER'} />} />
                      <Route key="member-view-selected-business-sales" path="member/view-selected-business-sales" element={<ViewSelectedBusinessSales role={'BUSINESS_MEMBER'} />} />
                      <Route key="member-view-selected-business-member-sales" path="member/view-selected-business-member-sales" element={<ViewSelectedBusinessMemberSales role={'BUSINESS_MEMBER'} />} />
                      <Route key="member-view-selected-order" path="member/view-selected-order" element={<ViewSelectedOrder role={'BUSINESS_MEMBER'} />} />
                    </Route>

                </Routes>
            </main>
          </Router>
        </ThemeProvider>
    </>  


  );
}

export default App;
