import React, { useState } from "react";
import { Trash } from 'react-bootstrap-icons'


const BusinessLogoUpload = ({businessLogo, setBusinessLogo}: any) => {

    const handleBusinessLogoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        //@ts-ignore
        const businessLogoUploaded = event.target.files[0];        
        if((businessLogoUploaded.size/1024) > 250) {
            setBusinessLogo(null);
            alert('Size of the icon can not exceed 250KB limit');
        } else {
            setBusinessLogo(businessLogoUploaded);
        }
    }

    return (
        <center>
            {businessLogo && (
                <div>
                    <img
                        alt="business logo not found"
                        width={"150px"}
                        height={"150px"}
                        src={typeof businessLogo === 'string' ? process.env.REACT_APP_SERVICE_URI + businessLogo : URL.createObjectURL(businessLogo)}
                    />
                    <br />
                    <button type="button" className="btn btn-outline-danger mt-2" onClick={() => setBusinessLogo(null)}><Trash size={20} /> Remove</button>
                </div>
            )}

            <br />
      
            <input
                type="file"
                id="businessLogo"
                name="businessLogo"
                accept=".jpg, .jpeg, .svg, .bmp, .png"
                onChange={handleBusinessLogoUpload}
            />
        </center>
    );
};

export default BusinessLogoUpload;