import React, { useState, useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import { BusinessDetails } from './BusinessDetails';
import { AdditionalInfo } from './AdditionalInfo';
import { AccessInfo } from './AccessInfo';
import { SquarePaymentGateway } from './SquarePaymentGateway';
import { BankDetails } from './BankDetails';
import { TaxDetails } from './TaxDetails';
import { RegistrationDetails } from './RegistrationDetails';
import axios from "axios";
import '../../../pages/Common/styles.css';
import { Features } from '../FeaturesManagement/Features';
import {useLocation} from "react-router-dom";
import SuperAdminNavbar from '../Navigation/SuperAdminNavbar';
import TextField from '@mui/material/TextField';
import { Modal } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import CircularProgress from "@mui/material/CircularProgress";
import Box from '@mui/material/Box';
import BusinessOwnerNavbar from '../../BusinessOwnerView/Navigation/BusinessOwnerNavbar';
import {store} from '../../../redux/store';
import { didLogout } from '../../Common/Logout';
import { getConfig, validateAccessToken } from '../../../utils/tokens';
import BorderedSection from '../../Common/BorderedSection';
import BusinessLogoUpload from '../../Common/BusinessLogoUpload';
import { isNull } from 'util';

const commonStyles = {
    bgcolor: 'background.paper',
    borderColor: 'text.primary',
    border: 1,
    ml: -1.5,
    p: 1
};

function ModifySelectedBusiness(props: any) {
    const navigate = useNavigate();
    
    const [accessCodeError, setAccessCodeError] = useState(false);

    const featuresURL = process.env.REACT_APP_SERVICE_URI + "features/";
    const uploadBusinessImageURL = process.env.REACT_APP_SERVICE_URI + "files/images/upload/";

    const [selectedFeatures, setSelectedFeatures] = useState<{title: string, feature_id: string}[]>([]);
    const [availableFeatures, setAvailableFeatures] = useState<Features[]>([]);
    const [isFetching, setIsFetching] = useState(false);

    const [businessDetails, setBusinessDetails] = useState<BusinessDetails>();
    const [additionalInfo, setAdditionalInfo] = useState<AdditionalInfo>();
    const [accessInfo, setAccessInfo] = useState<AccessInfo>();
    const [bankDetails, setBankDetails] = useState<BankDetails>();
    // const [squarePaymentGateway, setSquarePaymentGateway] = useState<SquarePaymentGateway>();
    const [taxDetails, setTaxDetails] = useState<TaxDetails>();
    const [registrationDetails, setRegistrationDetails] = useState<RegistrationDetails>();

    const [businessLogo, setBusinessLogo] = useState<File| null>(null);
    const businessLogoData = new FormData();

    const [loaded, setLoaded] = useState(false);

    const location = useLocation();

    const storeData = store.getState();

    const getBusiness = async (getBusinessURL: string, config: any) => {
        try {
            const response = await axios.get(getBusinessURL, config);
            return response.data;
        } catch (error) {
            return error;
        }
    }

    const getAvailableFeatures = async (config: any) => {
        try {
            const response = await axios.get(featuresURL, config);
            return response.data;
        } catch (error) {
            return error;
        }
    };

    const setData = async (accessToken: string) => {
        const businessProfileId = location.state.business_id;
        const getBusinessURL = process.env.REACT_APP_SERVICE_URI + "businesses/" + businessProfileId;

        await getBusiness(getBusinessURL, getConfig(accessToken))
        .then((data) => {
            const business_details = data.company_business_profile?.business_details ?? null
            if(business_details !== null)  {
                setBusinessDetails(Object.assign({}, {
                    business_name: business_details.business_name,
                    business_website_url: business_details.business_website_url,
                    edit_product_pin: business_details.edit_product_pin,
                    address_line_1: business_details.address_line_1,
                    address_line_2: business_details.address_line_2,
                    city: business_details.city,
                    state: business_details.state,
                    country: business_details.country,
                    post_code: business_details.post_code,
                    email: business_details.email,
                    mobile: business_details.mobile,
                    landline: business_details.landline,
                    general_message: business_details.general_message
                }))
            }

            const additional_info = data.company_business_profile?.additional_info ?? null
            if(additional_info !== null)  {
                setAdditionalInfo(Object.assign({}, {
                    currency_code: additional_info.currency_code,
                    currency_symbol: additional_info.currency_symbol,
                    notes: additional_info.notes,
                    message: additional_info.message,
                    remarks: additional_info.remarks,
                    business_logo_name: additional_info?.business_logo_name,
                    business_logo_path: additional_info?.business_logo_path,
                    business_logo_size: additional_info?.business_logo_size,
                    return_policy: additional_info.return_policy,
                    thank_you_message: additional_info.thank_you_message
                }))
                setBusinessLogo(additional_info.business_logo_path)
            }

            const access_info = data.company_business_profile?.access_info ?? null
            if( storeData.user.role === 'SUPER_ADMIN' && access_info !== null ){
                setAccessInfo(Object.assign({}, {
                    key: access_info.key,
                    secret: access_info.secret,
                    consumer_pass: access_info.consumer_pass,
                    name: access_info.name,
                    base_url: access_info.base_url,
                    base_path: access_info.base_path,
                    base_version: access_info.base_version,
                    port: access_info.port,
                    is_active_business: access_info.is_active_business,
                    is_banned_business: access_info.is_banned_business,
                }))
            }
            const bank_details = data.company_business_profile?.bank_details ?? null;
            if(bank_details !== null)  {
                setBankDetails(Object.assign({}, {
                    bank_name: bank_details.bank_name,
                    account_holder_name: bank_details.account_holder_name,
                    account_number: bank_details.account_number,
                    bsb: bank_details.bsb,
                    routing_number: bank_details.routing_number,
                    swift_code: bank_details.swift_code,
                    iban: bank_details.iban,
                    branch_name: bank_details.branch_name,
                    address: bank_details.address,
                    pay_id_email: bank_details.pay_id_email,
                    pay_id_mobile: bank_details.pay_id_mobile,
                    upi_payment_id: bank_details.upi_payment_id,
                    pay_id_other: bank_details.pay_id_other
                }))
            }
            
            // const square_payment_gateway = data.company_business_profile?.square_payment_gateway ?? null;
            // if(square_payment_gateway !== null)  {
            //     setSquarePaymentGateway({
            //         ...square_payment_gateway,
            //         is_active: square_payment_gateway.is_active,
            //       });
            // }

            const tax_details = data.company_business_profile?.tax_details ?? null;
            if(tax_details !== null) {
                setTaxDetails(Object.assign({}, {
                    category_one: tax_details.category_one,
                    value_one: tax_details.value_one,
                    category_two: tax_details.category_two,
                    value_two: tax_details.value_two
                }))
            }

            if(data.company_business_profile.registration_details !== null) {
                setRegistrationDetails(Object.assign({}, {
                    company_number: data.company_business_profile.registration_details.company_number,
                    company_other_details: data.company_business_profile.registration_details.company_other_details
                }))
            }
            
            setSelectedFeatures(data.nix_connect_app_profiles);
        })
        .catch((error) => {
            setIsFetching(false);
            alert('Loading failed!');
        });
    }

    useEffect(() => {
        if(didLogout()) {
            navigate("/");
        }
        setIsFetching(true);
        validateAccessToken().then((fetchedAccessToken) => {
            const accessToken = fetchedAccessToken || storeData.user.access_token as string;
            setLoaded(false);
            
            getAvailableFeatures(getConfig(accessToken))
            .then((data) => {
                setAvailableFeatures(data);
            })
            .catch((error) => {
                setIsFetching(false);
                alert('Loading failed!');
            });

            setData(accessToken).then(()=>{
                setLoaded(true);
                setIsFetching(false);
            })
        })
    },[]);

    const handleOnChangeFeatures = (event: any) => {
        const title = event.target.value;
        const feature_id= event.target.id;
        const isChecked = event.target.checked;
 
        if(feature_id === 'selectAll') {
            if (isChecked) {
                setSelectedFeatures(prevState => []);
                availableFeatures.map(availableFeature => {
                    let feature = Object.assign({}, {feature_id: availableFeature.id, title: availableFeature.title});
                    setSelectedFeatures(prevState => [...prevState, feature])
                })
            } else {
                setSelectedFeatures(prevState => []);
            }
        } else{
            if (isChecked) {
                setSelectedFeatures([...selectedFeatures, {'feature_id': feature_id, 'title': title}])
            } else {
                const filteredList = selectedFeatures.filter((item) => item.feature_id !== feature_id);
                setSelectedFeatures(filteredList);
            }
        }
    }

    const handleBusinessDetailsChange = (event: React.ChangeEvent<HTMLInputElement>, item: string) => {
        if (item === "business_name") {
            setBusinessDetails((previousState: any) => {
                return {...previousState, business_name:event.target.value}
            });
        } else if (item === "business_website_url") {
            setBusinessDetails((previousState: any) => {
                return {...previousState, business_website_url:event.target.value}
            });
        } else if (item === "edit_product_pin") {
            const inputText = event.target.value;
            const maxLength = 4;
            if (inputText.length <= maxLength) {
                setBusinessDetails((previousState: any) => {
                    return {...previousState, edit_product_pin:event.target.value}
                }); 
                setAccessCodeError(false);
            } else {
                setAccessCodeError(true);
            }
        } else if (item === "address_line_1") {
            setBusinessDetails((previousState: any) => {
                return {...previousState, address_line_1:event.target.value}
            });
        } else if (item === "address_line_2") {
            setBusinessDetails((previousState: any) => {
                return {...previousState, address_line_2:event.target.value}
            });
        } else if (item === "city") {
            setBusinessDetails((previousState: any) => {
                return {...previousState, city:event.target.value}
            });
        } else if (item === "state") {
            setBusinessDetails((previousState: any) => {
                return {...previousState, state:event.target.value}
            });
        } else if (item === "country") {
            setBusinessDetails((previousState: any) => {
                return {...previousState, country:event.target.value}
            });
        } else if (item === "post_code") {
            setBusinessDetails((previousState: any) => {
                return {...previousState, post_code:event.target.value}
            });
        } else if (item === "email") {
            setBusinessDetails((previousState: any) => {
                return {...previousState, email:event.target.value}
            });
        } else if (item === "mobile") {
            setBusinessDetails((previousState: any) => {
                return {...previousState, mobile:event.target.value}
            });
        } else if (item === "landline") {
            setBusinessDetails((previousState: any) => {
                return {...previousState, landline:event.target.value}
            });
        } else if (item === "general_message") {
            setBusinessDetails((previousState: any) => {
                return {...previousState, general_message:event.target.value}
            });
        }
    }

    const handleAdditionalInfoChange = (event: React.ChangeEvent<HTMLInputElement>, item: string) => {
        if (item === "currency_code") {
            setAdditionalInfo((previousState: any) => {
                return {...previousState, currency_code:event.target.value}
            });
        } else if (item === "currency_symbol") {
            setAdditionalInfo((previousState: any) => {
                return {...previousState, currency_symbol:event.target.value}
            });
        } else if (item === "notes") {
            setAdditionalInfo((previousState: any) => {
                return {...previousState, notes:event.target.value}
        });
        } else if (item === "message") {
            setAdditionalInfo((previousState: any) => {
                return {...previousState, message:event.target.value}
            });
        } else if (item === "remarks") {
            setAdditionalInfo((previousState: any) => {
                return {...previousState, remarks:event.target.value}
            });
        } else if (item === "return_policy") {
            setAdditionalInfo((previousState: any) => {
                return {...previousState, return_policy:event.target.value}
            });
        } else if (item === "thank_you_message") {
            setAdditionalInfo((previousState: any) => {
                return {...previousState, thank_you_message:event.target.value}
            });
        }
    }

    const handleAccessInfoChange = (event: React.ChangeEvent<HTMLInputElement>, item: string) => {
        if (item === "key") {
            setAccessInfo((previousState: any) => {
                return {...previousState, key:event.target.value}
        });
        } else if (item === "secret") {
            setAccessInfo((previousState: any) => {
                return {...previousState, secret:event.target.value}
            });
        } else if (item === "consumer_pass") {
            setAccessInfo((previousState: any) => {
                return {...previousState, consumer_pass:event.target.value}
            });
        } else if (item === "name") {
            setAccessInfo((previousState: any) => {
                return {...previousState, name:event.target.value}
            });
        } else if (item === "base_url") {
            let baseUrl = event.target.value;
            if (baseUrl[0] === '/') {
                baseUrl = baseUrl.substring(1);
            }
            setAccessInfo((previousState: any) => {
                return {...previousState, base_url:event.target.value}
            });
        } else if (item === "base_path") {
            setAccessInfo((previousState: any) => {
                return {...previousState, base_path:event.target.value}
            });
        } else if (item === "base_version") {
            setAccessInfo((previousState: any) => {
                return {...previousState, base_version:event.target.value}
            });
        } else if (item === "port") {
            setAccessInfo((previousState: any) => {
                return {...previousState, port:Number(event.target.value)}
            });
        } else if (item === "is_active_business") {
            setAccessInfo((previousState: any) => {
                return {...previousState, is_active_business:event.target.checked}
            });
        } else if (item === "is_banned_business") {
            setAccessInfo((previousState: any) => {
                return {...previousState, is_banned_business:event.target.checked}
            });
            if(event.target.checked) {
                setAccessInfo((previousState: any) => {
                    return {...previousState, is_active_business:false}
                });
            }
        }
    }

    const handleBankDetailsChange = (event: React.ChangeEvent<HTMLInputElement>, item: string) => {
        if (item === "bank_name") {
            setBankDetails((previousState: any) => {
                return {...previousState, bank_name:event.target.value}
        });
        } else if (item === "account_holder_name") {
            setBankDetails((previousState: any) => {
                return {...previousState, account_holder_name:event.target.value}
            });
        } else if (item === "account_number") {
            setBankDetails((previousState: any) => {
                return {...previousState, account_number:event.target.value}
            });
        } else if (item === "bsb") {
            setBankDetails((previousState: any) => {
                return {...previousState, bsb:event.target.value}
            });
        } else if (item === "routing_number") {
            setBankDetails((previousState: any) => {
                return {...previousState, routing_number:event.target.value}
            });
        } else if (item === "swift_code") {
            setBankDetails((previousState: any) => {
                return {...previousState, swift_code:event.target.value}
            });
        } else if (item === "iban") {
            setBankDetails((previousState: any) => {
                return {...previousState, iban:event.target.value}
            });
        } else if (item === "branch_name") {
            setBankDetails((previousState: any) => {
                return {...previousState, branch_name:event.target.value}
            });
        } else if (item === "address") {
            setBankDetails((previousState: any) => {
                return {...previousState, address:event.target.value}
            });
        } else if (item === "pay_id_email") {
            setBankDetails((previousState: any) => {
                return {...previousState, pay_id_email:event.target.value}
            });
        } else if (item === "pay_id_mobile") {
            setBankDetails((previousState: any) => {
                return {...previousState, pay_id_mobile:event.target.value}
            });
        } else if (item === "upi_payment_id") {
            setBankDetails((previousState: any) => {
                return {...previousState, upi_payment_id:event.target.value}
            });
        } else if (item === "pay_id_other") {
            setBankDetails((previousState: any) => {
                return {...previousState, pay_id_other:event.target.value}
            });
        }
    }

    // Set Square Payment realted
    // const handleSquareGatewayChange = (
    //     event: React.ChangeEvent<HTMLInputElement>,
    //     item: string
    //     ) => {
    //     setSquarePaymentGateway((previousState: any) => {
    //         return {
    //         ...previousState,
    //         [item]: item === "is_active" ? event.target.checked : event.target.value,
    //         };
    //     });
    // };
    
    const handleTaxDetailsChange = (event: React.ChangeEvent<HTMLInputElement>, item: string) => {
        if (item === "value_one") {
            setTaxDetails((previousState: any) => {
                return {...previousState, value_one:event.target.value}
        });
        } else if (item === "category_one") {
            setTaxDetails((previousState: any) => {
                return {...previousState, category_one:event.target.value}
            });
        } else if (item === "value_two") {
            setTaxDetails((previousState: any) => {
                return {...previousState, value_two:event.target.value}
            });
        } else if (item === "category_two") {
            setTaxDetails((previousState: any) => {
                return {...previousState, category_two:event.target.value}
            });
        }
    }

    const handleRegistrationDetailsChange = (event: React.ChangeEvent<HTMLInputElement>, item: string) => {
        if (item === "company_number") {
            setRegistrationDetails((previousState: any) => {
                return {...previousState, company_number:event.target.value}
        });
        } else if (item === "company_other_details") {
            setRegistrationDetails((previousState: any) => {
                return {...previousState, company_other_details:event.target.value}
            });
        }
    }

    const patchBusiness = (modifyBusinessURL: string, additionalInfoToPatch: AdditionalInfo, config: any) => {
        let modifiedBusinessData = null;
        if(storeData.user.role === 'SUPER_ADMIN') {
            modifiedBusinessData = {
                nix_connect_app_profiles:selectedFeatures,
                company_business_profile: {
                    business_details: businessDetails,
                    additional_info: additionalInfoToPatch,
                    access_info: accessInfo,
                    bank_details: bankDetails? bankDetails : null,
                    // square_payment_gateway: squarePaymentGateway,
                    tax_details: taxDetails? taxDetails : null,
                    registration_details: registrationDetails? registrationDetails : null
                }
            }
        } else {
            modifiedBusinessData = {
                nix_connect_app_profiles:selectedFeatures,
                company_business_profile: {
                    business_details: businessDetails,
                    additional_info: additionalInfoToPatch,
                    bank_details: bankDetails? bankDetails : null,
                    // square_payment_gateway: squarePaymentGateway,
                    tax_details: taxDetails? taxDetails : null,
                    registration_details: registrationDetails? registrationDetails : null
                }
            }
        }

        axios.patch(modifyBusinessURL, modifiedBusinessData, config)
        .then((response) => {
            const message = response.data.message;
            setIsFetching(false);
            if (message.toUpperCase() === 'Success'.toUpperCase()) {
                alert('Successfully modified business');
                
                if (storeData.user.role === 'SUPER_ADMIN') {
                    navigate("/admin/view-modify-and-delete-business");
                } else if (storeData.user.role === 'BUSINESS_OWNER') {
                    navigate("/owner/view-modify-and-delete-business");
                }
            } else {
                alert('Unable to modify business. Please try after some time.');
                return;
            }
        })
        .catch((error) => {
            setIsFetching(false);
            alert('Unable to modify business. Please try after some time.');
            return;
        });
    }

    const handleModifyBusiness = (event: any) => {
        event.preventDefault();
        const businessProfileId = location.state.business_id;
        const modifyBusinessURL = process.env.REACT_APP_SERVICE_URI + "businesses/" + businessProfileId + "/";
        setIsFetching(true);
        validateAccessToken().then((fetchedAccessToken) => {
            const accessToken = fetchedAccessToken || storeData.user.access_token as string;
            let additionalInfoToPatch: AdditionalInfo = additionalInfo || ({} as any) as AdditionalInfo;

            if(additionalInfo?.business_logo_name !== businessLogo?.name && additionalInfo?.business_logo_size !== businessLogo?.size) {
                if(businessLogo && typeof businessLogo === 'object') {
                    businessLogoData.append("business_logo", businessLogo as File);
                    businessLogoData.append("business_id", businessProfileId as string);

                    const config = getConfig(accessToken);
                    //@ts-ignore
                    config.headers['Content-Type'] = 'multipart/form-data';
                    axios.put(uploadBusinessImageURL, businessLogoData, config)
                    .then((response) => {
                        if (response.data.message.toUpperCase() === 'Success'.toUpperCase()) {
                            additionalInfoToPatch['business_logo_name'] = response.data.business_logo_name;
                            additionalInfoToPatch['business_logo_path'] = response.data.public_url;
                            additionalInfoToPatch['business_logo_size'] = response.data.business_logo_size;
                            patchBusiness(modifyBusinessURL, additionalInfoToPatch, getConfig(accessToken));
                        }
                    })
                    .catch((error) => {
                        setIsFetching(false);
                        alert('Unable to modify business. Please try after some time.');
                        return;
                    })
                } else {
                    if(additionalInfoToPatch['business_logo_path'] && typeof businessLogo !== 'string')
                    {
                        const deleteBusinessLogoURL = process.env.REACT_APP_SERVICE_URI + 'files/images/delete/' + businessProfileId + "/" + additionalInfoToPatch.business_logo_name + "/";
                        axios.delete(deleteBusinessLogoURL, getConfig(accessToken))
                        .then((response) => {
                            if (response.data.message.toUpperCase() === 'Success'.toUpperCase()) {
                                additionalInfoToPatch['business_logo_name'] = '';
                                additionalInfoToPatch['business_logo_path'] = '';
                                additionalInfoToPatch['business_logo_size'] = '';
                                patchBusiness(modifyBusinessURL, additionalInfoToPatch, getConfig(accessToken));
                            }
                        })
                    } else {
                        patchBusiness(modifyBusinessURL, additionalInfoToPatch, getConfig(accessToken));
                    }
                }
            } else {
                patchBusiness(modifyBusinessURL, additionalInfoToPatch, getConfig(accessToken));
            }            
        });
    }

    const handleReset = () => {
        setSelectedFeatures([]);
        setBusinessDetails({
            business_name: '',
            business_website_url: '',
            address_line_1: '',
            address_line_2: '',
            city: '',
            state: '',
            country: '',
            post_code: '',
            email: '',
            mobile: '',
            landline: '',
            general_message: '',
            edit_product_pin: ''
        });

        setAdditionalInfo({
            currency_code: '',
            currency_symbol: '',
            notes: '',
            message: '',
            remarks: '',
            business_logo_name:'',
            business_logo_path:'',
            business_logo_size:'',
            return_policy: '',
            thank_you_message: ''
        });

        setAccessInfo({
            key: '',
            secret: '',
            consumer_pass: '',
            name: '',
            base_url: '',
            base_path: '',
            base_version: '',
            port: 0,
            is_active_business: false,
            is_banned_business: false
        });

        setBankDetails({
            bank_name: '',
            account_holder_name: '',
            account_number: '',
            bsb: '',
            routing_number: '',
            swift_code: '',
            iban: '',
            branch_name: '',
            address: '',
            pay_id_email: '',
            pay_id_mobile: '',
            upi_payment_id: '',
            pay_id_other: ''
        });

        // Commenting for now..required for later
        // setSquarePaymentGateway({
        //     application_id: '',
        //     access_token: '',
        //     base_url: '',
        //     base_path: '',
        //     is_active: false
        // });

        setTaxDetails({
            value_one: '',
            category_one: '',
            value_two: '',
            category_two: ''
        });

        setRegistrationDetails({
            company_number: '',
            company_other_details: ''
        });
    }

    return (
        <>
            {props.role === 'SUPER_ADMIN' ? <SuperAdminNavbar /> : props.role === 'BUSINESS_OWNER' ? <BusinessOwnerNavbar /> : null}
            <Modal
                open={isFetching}
                style={{
                    position: 'absolute',
                    height: 50,
                    width: 100,
                    margin: 'auto'
                }}
            >
                <CircularProgress />
            </Modal>
            {loaded?
            <div className='ag-grid-size'>
                <div className='ms-4'>
                    <form onSubmit={handleModifyBusiness} onReset={handleReset}>
                        <h3 style={{display: 'flex', justifyContent: 'center', marginTop: '24px'}}>Modify Business</h3>
                        <label htmlFor='businessDetails' style={{ fontSize: 20, fontWeight: 'bold' }}>Business Details</label>
                        <label htmlFor='businessDetails' style={{ fontSize: 16, fontWeight: 'normal', fontStyle: 'italic'}}> (Required)</label>

                        <div id='businessDetails'>
                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Business Name" value={businessDetails?.business_name} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "business_name")} required variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Business Website URL" value={businessDetails?.business_website_url} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "business_website_url")} variant="outlined" />
                                {/* <TextField id="outlined-controlled" className="form-control me-3 col" label="Product Access Code (4 Digits)" value={businessDetails?.edit_product_pin} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "edit_product_pin")} required variant="outlined" /> */}
                            
                                <TextField
                                    id="outlined-controlled"
                                    className="form-control me-3 col"
                                    label="Product Access Code (4 Digits)"
                                    type="number" // Set type to "number"
                                    value={businessDetails?.edit_product_pin}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "edit_product_pin")}
                                    required
                                    variant="outlined"
                                    inputProps={{ maxLength: 4, type: "number" }}
                                    helperText={accessCodeError ? '4 digits only' : ''}
                                />
                            </div>

                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Address Line 1" value={businessDetails?.address_line_1} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "address_line_1")} required variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Address Line 2" value={businessDetails?.address_line_2} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "address_line_2")} variant="outlined" />
                                <TextField id="outlined-controlled" type="number" className="form-control me-3 col" label="Post Code" value={businessDetails?.post_code} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "post_code")} required variant="outlined" />
                            </div>

                            <div className='form-group my-2 row'>
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="City" value={businessDetails?.city} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "city")} required variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="State" value={businessDetails?.state} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "state")} required variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Country" value={businessDetails?.country} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "country")} required variant="outlined" />
                            </div>

                            <div className='form-group my-2 row'>
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Email" value={businessDetails?.email} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "email")} required variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Mobile" value={businessDetails?.mobile} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "mobile")} required variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Landline" value={businessDetails?.landline} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "landline")} variant="outlined" />
                            </div>

                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="General Message" value={businessDetails?.general_message} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "general_message")} variant="outlined" />
                            </div>
                        </div>

                        {storeData.user.role === 'SUPER_ADMIN'? 
                            <>
                                <label htmlFor='accessInfo' style={{ fontSize: 20, fontWeight: 'bold' }}>Access Info</label>
                                <label htmlFor='accessInfo' style={{ fontSize: 16, fontWeight: 'normal', fontStyle: 'italic'}}> (Required)</label>
                                <div id='accessInfo'>
                                    <div className="form-group my-2 row">
                                        <TextField id="outlined-controlled" className="form-control me-3 col" label="Key" value={accessInfo?.key} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAccessInfoChange(event, "key")} variant="outlined" />
                                        <TextField id="outlined-controlled" className="form-control me-3 col" label="Secret" value={accessInfo?.secret} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAccessInfoChange(event, "secret")} variant="outlined" />
                                    </div>
                                    <div className="form-group my-2 row">
                                        <TextField id="outlined-controlled" className="form-control me-3 col" label="Consumer Pass" value={accessInfo?.consumer_pass} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAccessInfoChange(event, "consumer_pass,")} variant="outlined" />
                                        <TextField id="outlined-controlled" className="form-control me-3 col" label="Name" value={accessInfo?.name} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAccessInfoChange(event, "name")} required variant="outlined" />
                                        <TextField id="outlined-controlled" className="form-control me-3 col" label="Base URL" value={accessInfo?.base_url} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAccessInfoChange(event, "base_url")} required variant="outlined" />
                                    </div>
                                    <div className="form-group my-2 row">
                                        <TextField id="outlined-controlled" className="form-control me-3 col" label="Base Path" value={accessInfo?.base_path} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAccessInfoChange(event, "base_path")} variant="outlined" />
                                        <TextField id="outlined-controlled" className="form-control me-3 col" label="Base Version" value={accessInfo?.base_version} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAccessInfoChange(event, "base_version")} required variant="outlined" />
                                        <TextField id="outlined-controlled" type="number" className="form-control me-3 col" label="Port" value={accessInfo?.port} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAccessInfoChange(event, "port")} variant="outlined" />
                                    </div>
                                    <div className="form-group my-2 row">
                                        <div className="form-check col form-switch">
                                            <input className="form-check-input" type="checkbox" role="switch" checked={accessInfo?.is_active_business} onChange={event => handleAccessInfoChange(event, "is_active_business")} disabled={accessInfo?.is_banned_business}/>
                                            <label className="form-check-label" htmlFor="isActiveBusiness">Is Active Business</label>
                                        </div>

                                        <div className="form-check col form-switch">
                                            <input 
                                                className="form-check-input" 
                                                type="checkbox" 
                                                role="switch" 
                                                checked={accessInfo?.is_banned_business} 
                                                onChange={event => handleAccessInfoChange(event, "is_banned_business")}/>
                                            <label className="form-check-label" htmlFor="isBannedBusiness">Is Banned Business</label>
                                        </div>
                                    </div>
                                </div>
                            </>
                        : null}
                        <label htmlFor='bankDetails' style={{ fontSize: 20, fontWeight: 'bold' }}>Bank Details</label>
                        <label htmlFor='bankDetails' style={{ fontSize: 16, fontWeight: 'normal', fontStyle: 'italic'}}> (Optional)</label>
                        <div id='bankDetails'>
                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Bank Name" value={bankDetails?.bank_name} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "bank_name")} variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Account Holder Name" value={bankDetails?.account_holder_name} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "account_holder_name")} variant="outlined" />
                            </div>
                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Account Number" value={bankDetails?.account_number} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "account_number")} variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Bsb" value={bankDetails?.bsb} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "bsb")} variant="outlined" />
                            </div>
                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Routing Number" value={bankDetails?.routing_number} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "routing_number")} variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Swift Code" value={bankDetails?.swift_code} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "swift_code")} variant="outlined" />
                            </div>
                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Iban" value={bankDetails?.iban} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "iban")} variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Branch Name" value={bankDetails?.branch_name} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "branch_name")} variant="outlined" />
                            </div>
                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Address" value={bankDetails?.address} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "address")} variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Pay Id Email" value={bankDetails?.pay_id_email} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "pay_id_email")} variant="outlined" />
                            </div>
                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Pay Id Mobile" value={bankDetails?.pay_id_mobile} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "pay_id_mobile")} variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="UPI Payment Id" value={bankDetails?.upi_payment_id} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "upi_payment_id")} variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Pay Id Other" value={bankDetails?.pay_id_other} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "pay_id_other")} variant="outlined" />
                            </div>
                        </div>

                        {/* <div>
                            <div>
                                <label htmlFor='squarePaymentGateway' style={{ fontSize: 20, fontWeight: 'bold' }}>Payment Gateways</label>
                            </div>
                            <div>
                                <label htmlFor='squarePaymentGateway' style={{ fontSize: 18, fontWeight: 'bold' }}>1. Square</label>
                            </div>
                        </div>
                        <div id='squarePaymentGateway'>
                            <div className="form-group my-2 row">
                                <TextField 
                                    id="outlined-controlled" 
                                    className="form-control me-3 col" 
                                    label="Gateway Base URL" 
                                    value={squarePaymentGateway?.base_url} 
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSquareGatewayChange(event, "base_url")} 
                                    required variant="outlined" />
                                <TextField 
                                    id="outlined-controlled" 
                                    className="form-control me-3 col" 
                                    label="Gateway Base Path" 
                                    value={squarePaymentGateway?.base_path} 
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSquareGatewayChange(event, "base_path")} 
                                    required variant="outlined" />
                            </div>
                            <div className="form-group my-2 row">
                                <TextField 
                                    id="outlined-controlled" 
                                    className="form-control me-3 col" 
                                    label="Application ID" 
                                    value={squarePaymentGateway?.application_id} 
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSquareGatewayChange(event, "application_id")} 
                                    required variant="outlined" />
                                <TextField 
                                    id="outlined-controlled" 
                                    className="form-control me-3 col" 
                                    label="Access Token" 
                                    value={squarePaymentGateway?.access_token} 
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSquareGatewayChange(event, "access_token")} 
                                    required variant="outlined" />
                            </div>
                            <div className="form-group my-2 row">
                                <div className="form-check col form-switch">
                                    <input 
                                        className="form-check-input" 
                                        type="checkbox" 
                                        role="switch" 
                                        checked={squarePaymentGateway?.is_active} 
                                        onChange={event => handleSquareGatewayChange(event, "is_active")} 
                                        />
                                    <label className="form-check-label" htmlFor="isActive">
                                        Is Active
                                    </label>
                                </div>
                            </div>
                        </div> */}

                        <label htmlFor='taxDetails' style={{ fontSize: 20, fontWeight: 'bold' }}>Tax Details</label>
                        <label htmlFor='taxDetails' style={{ fontSize: 16, fontWeight: 'normal', fontStyle: 'italic'}}> (Optional)</label>

                        <div id='taxDetails'>
                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Standard Tax Rate" value={taxDetails?.category_one} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleTaxDetailsChange(event, "category_one")} variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="In Percent" value={taxDetails?.value_one} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleTaxDetailsChange(event, "value_one")} variant="outlined" />
                            </div>
                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Alternative Tax Rate" value={taxDetails?.category_two} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleTaxDetailsChange(event, "category_two")} variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="In Percent" value={taxDetails?.value_two} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleTaxDetailsChange(event, "value_two")} variant="outlined" />
                            </div>
                        </div>

                        <label htmlFor='registrationDetails' style={{ fontSize: 20 }}>Business Registration Details</label>
                        <label htmlFor='registrationDetails' style={{ fontSize: 16, fontWeight: 'normal', fontStyle: 'italic'}}> (Optional)</label>

                        <div id='registrationDetails'>
                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Registration Number / ABN" value={registrationDetails?.company_number} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleRegistrationDetailsChange(event, "company_number")} variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Additional Company Information" value={registrationDetails?.company_other_details} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleRegistrationDetailsChange(event, "company_other_details")} variant="outlined" />
                            </div>
                        </div>

                        <label htmlFor='additionalInfo' style={{ fontSize: 20, fontWeight: 'bold' }}>Additional Info</label>
                        <label htmlFor='additionalInfo' style={{ fontSize: 16, fontWeight: 'normal', fontStyle: 'italic'}}> (Optional)</label>

                        <div id="additionalInfo">
                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Currency Code" value={additionalInfo?.currency_code} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAdditionalInfoChange(event, "currency_code")} variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Currency Symbol" value={additionalInfo?.currency_symbol} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAdditionalInfoChange(event, "currency_symbol")} variant="outlined" />
                            </div>
                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Notes" value={additionalInfo?.notes} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAdditionalInfoChange(event, "notes")} variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Message" value={additionalInfo?.message} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAdditionalInfoChange(event, "message")} variant="outlined" />
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Remarks" value={additionalInfo?.remarks} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAdditionalInfoChange(event, "remarks")} variant="outlined" />
                            </div>
                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Return Policy" value={additionalInfo?.return_policy} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAdditionalInfoChange(event, "return_policy")} variant="outlined" multiline minRows={3} maxRows={20} />
                            </div>
                            <div className="form-group my-2 row">
                                <TextField id="outlined-controlled" className="form-control me-3 col" label="Thank you Message" value={additionalInfo?.thank_you_message} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAdditionalInfoChange(event, "thank_you_message")} variant="outlined" multiline minRows={3} maxRows={20} />
                            </div>
                            <BorderedSection title={'Business Logo'}>
                                <BusinessLogoUpload businessLogo={businessLogo} setBusinessLogo={setBusinessLogo} />
                            </BorderedSection>
                        </div>


                        <Box sx={{ ...commonStyles, borderRadius: 1 }}>
                            <label htmlFor='features' style={{ fontSize: 20, fontWeight: 'bold' }}>Select Features</label>
                            <div id='features'>

                                <input
                                    type="checkbox"
                                    name="features"
                                    id='selectAll'
                                    value={'Select All'}
                                    checked={availableFeatures.length === selectedFeatures.length}
                                    onChange={handleOnChangeFeatures}
                                />
                                <label>Select All</label>
                                {availableFeatures.map((item, index) => {
                                    return (
                                        <div key={item.id} className="checkbox-container">
                                        <input
                                            type="checkbox"
                                            name="features"
                                            id={item.id}
                                            value={item.title}
                                            checked={selectedFeatures.some(selectedFeature => selectedFeature.feature_id === item.id)}
                                            onChange={handleOnChangeFeatures}
                                        />
                                        <label>{item.title}</label>
                                        </div>
                                    );
                                })}

                            </div>
                        </Box>
                        <div className="button-container">
                            <button type="submit" className="btn btn-primary">Modify Business</button>
                            <button type="reset" className="btn-clear">Clear</button>
                        </div>
                    </form>
                </div>
            </div>: null}
        </>
    )
}

export default ModifySelectedBusiness;