import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../SuperAdminView/Navigation/SuperAdminNavbar.css';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import UserProfile from '../../Common/UserProfile';
import theme from "../../../themes/theme";
import {
	useMediaQuery,
  } from "@mui/material";


function BusinessOwnerNavbar() {
  // const [showBusiness, setShowBusiness] = useState(false);
  // const [showUsers, setShowUsers] = useState(false);
  // const [showSales, setShowSales] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Navbar collapseOnSelect expand="lg" className="text-white bg-body-tertiary navbar" variant='dark'>
      <Container>
        {/* <Navbar.Brand href="/owner/home">Nix Connect</Navbar.Brand> */}
        <Navbar.Brand href="/admin/home">
          <img 
            src={process.env.PUBLIC_URL + '/resources/nixel_website_logo.png'} 
            alt="Company Logo" 
            style={{ maxWidth: isMobile ? 56 : 64 , marginRight: 18, cursor: 'pointer' }}
            />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto nav-item text-light">

            <NavDropdown title="Business" className='text-white' id="collapsible-nav-dropdown"
            // show={showBusiness} onMouseEnter={() => setShowBusiness(prevState => !prevState)} onMouseLeave={() => setShowBusiness(prevState => !prevState)}
             >
              <NavDropdown.Item as={Link} to="/owner/view-modify-and-delete-business">
                View, Modify and Delete
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Users" id="collapsible-nav-dropdown"
            // show={showUsers} onMouseEnter={() => setShowUsers(prevState => !prevState)} onMouseLeave={() => setShowUsers(prevState => !prevState)}
            >
              <NavDropdown.Item as={Link} to="/owner/invite-user">Invite User</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/owner/pending-users">Pending Users</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/owner/active-users">Active Users</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Sales" id="collasible-nav-dropdown"
            // show={showSales} onMouseEnter={() => setShowSales(prevState => !prevState)} onMouseLeave={() => setShowSales(prevState => !prevState)}
            >
              <NavDropdown.Item as={Link} to="/owner/view-sales">View Sales</NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <UserProfile />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default BusinessOwnerNavbar;