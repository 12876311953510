import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import './styles.css';
import CircularProgress from "@mui/material/CircularProgress";
import theme from "../../themes/theme";
import { 
    Modal,
    Container,
    AppBar,
    Grid,
    Paper,
    Box,
    ThemeProvider,
    Toolbar,
    Typography,
    Stack,
    TextField,
    Button,
    Menu,
    MenuItem,
    useMediaQuery,
    IconButton,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { BusinessDetails } from './BusinessDetails';
import { BankDetails } from './BankDetails';
import { TaxDetails } from './TaxDetails';
import { RegistrationDetails } from './RegistrationDetails';
import { APP_NAME, APP_TAG_LINE } from './HomePageInformation';
import NavBarGeneral from './NavBarGeneral';
import Footer from './Footer';

function EnrollYourBusiness() {
    const navigate = useNavigate();
    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
      }, []); 

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const enrolYourBusinessURL = process.env.REACT_APP_SERVICE_URI + "enrol_your_business/";
    const loginURL = "/login";
    const homeURL = "/home";
    const enrolNow = "/enroll-your-business";
    const deleteNow = "/delete-your-business";
    
    const [isCaptchaChecked, setIsCaptchaChecked] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    const [businessDetails, setBusinessDetails] = useState<BusinessDetails>();
    const [bankDetails, setBankDetails] = useState<BankDetails>();
    // const [taxDetails, setTaxDetails] = useState<TaxDetails>();
    const [taxDetails, setTaxDetails] = useState<TaxDetails>({
        category_one: 'Standard Tax Rate',  
        value_one: '',
        category_two: 'Alternative Tax Rate',
        value_two: '',
    });

    const [registrationDetails, setRegistrationDetails] = useState<RegistrationDetails>();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenuOpen = (event: any) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleMenuClose = () => {
      setAnchorEl(null);
    };
  
    const handleHomeClick = () => {
      handleMenuClose();
      navigate(homeURL);
    };

    const handleEnrolNowClick = () => {
        handleMenuClose();
        navigate(enrolNow);
    };

    const handleDeleteClick = () => {
        handleMenuClose();
        navigate(deleteNow);
    };


    const handleSignInClick = () => {
        handleMenuClose();
        navigate(loginURL);
      };

    
    const handleCaptchaChange = () => {
        setIsCaptchaChecked(!isCaptchaChecked);
      };

    const handleBusinessDetailsChange = (event: React.ChangeEvent<HTMLInputElement>, item: string) => {
        setBusinessDetails((previousState: any) => {
            return { ...previousState, [item]: event.target.value };
        });
    };

    const handleBankDetailsChange = (event: React.ChangeEvent<HTMLInputElement>, item: string) => {
        setBankDetails((previousState: any) => {
            return { ...previousState, [item]: event.target.value };
        });
    };

    const handleTaxDetailsChange = (event: React.ChangeEvent<HTMLInputElement>, item: string) => {
        setTaxDetails((previousState: any) => {
            return { ...previousState, [item]: event.target.value };
        });
    };

    const handleRegistrationDetailsChange = (event: React.ChangeEvent<HTMLInputElement>, item: string) => {
        setRegistrationDetails((previousState: any) => {
            return { ...previousState, [item]: event.target.value };
        });
    };

    const handleEnrollYourBusiness = (event: any) => {
        event.preventDefault();

        if (isCaptchaChecked) {
            setIsFetching(true);
            axios.post(enrolYourBusinessURL, {
                business_details: businessDetails,
                bank_details: bankDetails? bankDetails : null,
                tax_details: taxDetails? taxDetails : null,
                business_registration_details: registrationDetails? registrationDetails : null
            })
            .then((response) => {
                const message = response.data.message;
                const enrolId = response.data.enrol_id;
                if (message.toUpperCase() === 'Success'.toUpperCase()) {
                    alert(`Successfully Enrolled your business.\nYour enrollment ID is ${enrolId}`);
                    setIsFetching(prevState => !prevState)
                    navigate("/");
                } else {
                    alert('Please enter valid details');
                }
                setIsFetching(false);
            })
            .catch((error) => {
                setIsFetching(false);
                alert('Please enter valid details');
                navigate("");
            }); 
          } else {
            alert('Please confirm that you are not a robot before submitting.');
          }
    }

    const gridContainerStyle: React.CSSProperties = {
        padding: '16px',
        height: '100%',
        width: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: isSmallScreen ? '112px' : '136px',
        marginBottom: '40px',
        overflow: 'hidden',
        backgroundColor: '#FFFFFF',
    };

    const bodyContainerStyle: React.CSSProperties = {
        backgroundColor: 'linear-gradient(to bottom right, #FAF8F0, #FAF8F0)',
        padding: '12px', 
    };

    const cardStyle: React.CSSProperties = {
        paddingTop: '16px', 
        paddingBottom: '16px',
        paddingRight: '8px', 
        paddingLeft: '8px', 
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const titleTextStyle: React.CSSProperties = {
        textAlign: 'center',
        marginTop: '0px', 
        color: '#004AAD', 
        fontSize: '36px',
        fontFamily: 'Poppins',
        fontWeight: 'regular',
        fontStyle: 'normal',
        letterSpacing: '1.5px', 
        lineHeight: 1.2,
    };

    const bodyTextStyle: React.CSSProperties = {
        textAlign: 'justify',
        justifyContent: 'start',
        marginTop: '16px',
        color: '#333', 
        fontSize: '18px',
        fontFamily: 'Merriweather, serif',
        fontWeight: 400,
        fontStyle: 'normal',
        letterSpacing: '1.25px',
        lineHeight: 1.7,
      };

    const mainInfoTextStyle: React.CSSProperties = {
        textAlign: 'justify',
        marginTop: '8px',
        color: '#303030', // Text color set to black for better contrast
        fontFamily: 'Merriweather, sans',
        fontWeight: 'normal', // Use 'normal' for regular weight
        fontStyle: 'normal',
        letterSpacing: '1.25px',
        lineHeight: 1.7,
    };  

    
      const handleNavBarItemClick = (key: String, value:String) => {
        if (key === 'menu_0') {
            handleHomeClick();
        } else if (key === 'menu_1') {
            handleDeleteClick();
        } else if (key === 'menu_2') {
            handleSignInClick();
        }
    };

    const navBarProps = {
        items: ['Home','UnEnrol', 'Sign In'],
        onItemClick: handleNavBarItemClick,
    };  

    return (
        <ThemeProvider theme={theme}>
            <NavBarGeneral { ...navBarProps } />
            <Container>
                <form onSubmit={handleEnrollYourBusiness}>
                    <Paper elevation={2} style={{ ...gridContainerStyle, display: 'flex', flexDirection: 'column', borderRadius: '8px' }}>
                        <Grid container spacing={2} style={{ flex: 1 }}>
                            <Grid item xs={12}  style={{ display: 'flex', flexDirection: 'column' }}>
                                <Paper elevation={4} style={{ ...bodyContainerStyle, flex: 1, borderRadius: '8px', backgroundColor: '#004AAD' }}>
                                    <Stack direction="column" spacing={0} style={{ ...cardStyle }}>
                                        <Typography gutterBottom variant="h4" style={{ ...titleTextStyle, color: '#FFFFFF', fontSize: '32px' }}>
                                        Enrol Your Business with Nix Connect Platform
                                        </Typography>
                                    </Stack>
                                </Paper>
                            </Grid>       
                            <Grid item xs={12}  >
                                <Modal
                                    open={isFetching}
                                    style={{
                                        position: 'absolute',
                                        height: 50,
                                        width: 100,
                                        margin: 'auto'
                                    }} >
                                    <CircularProgress />
                                </Modal> 
                                <Stack id="enrol-business" direction="column" spacing={0} style={{padding: '0px'}}>
                                    <Box>
                                        <label htmlFor='businessDetails' style={{ fontSize: 20, fontWeight: 'bold', marginTop: 25 }}>Business Details</label>
                                        <label htmlFor='businessDetails' style={{ fontSize: 16, fontWeight: 'normal', fontStyle: 'italic', marginTop: 25}}> (Required)</label>
                                    </Box>
                                    <Box id="outlined-controlled" className="form-group-box row">
                                        <TextField 
                                            id="outlined-controlled" 
                                            className="form-control me-3 col" 
                                            label="Business Name" 
                                            InputLabelProps={{
                                                style: { color: 'grey' },
                                            }}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "business_name")} 
                                            required 
                                            variant="outlined" />
                                        <TextField id="outlined-controlled" className="form-control me-3 col" label="Business Website URL" InputLabelProps={{ style: { color: 'grey' }}} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "business_website_url")} variant="outlined" />
                                    </Box> 

                                    <Box id="outlined-controlled" className="form-group-box row">
                                        <TextField id="outlined-controlled" className="form-control me-3 col" label="Address Line 1" InputLabelProps={{ style: { color: 'grey' }}} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "address_line_1")} required variant="outlined" />
                                        <TextField id="outlined-controlled" className="form-control me-3 col" label="Address Line 2" InputLabelProps={{ style: { color: 'grey' }}} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "address_line_2")} variant="outlined" />
                                        <TextField id="outlined-controlled" className="form-control me-3 col" label="City" InputLabelProps={{ style: { color: 'grey' }}} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "city")} required variant="outlined" />               
                                    </Box>
                                    <Box id="outlined-controlled" className="form-group-box row">
                                        <TextField id="outlined-controlled" className="form-control me-3 col" label="State" InputLabelProps={{ style: { color: 'grey' }}} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "state")} required variant="outlined" />
                                        <TextField id="outlined-controlled" className="form-control me-3 col" label="Country" InputLabelProps={{ style: { color: 'grey' }}} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "country")} required variant="outlined" />
                                        <TextField id="outlined-controlled" type="number" className="form-control me-3 col" label="Post Code" InputLabelProps={{ style: { color: 'grey' }}} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "post_code")} required variant="outlined" />
                                    </Box>
                                    <Box id="outlined-controlled" className="form-group-box row">
                                        <TextField id="outlined-controlled" className="form-control me-3 col" label="Business Email" InputLabelProps={{ style: { color: 'grey' }}} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "email")} required variant="outlined" />
                                        <TextField id="outlined-controlled" className="form-control me-3 col" label="Business Mobile" InputLabelProps={{ style: { color: 'grey' }}} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "mobile")} required variant="outlined" />
                                        <TextField id="outlined-controlled" className="form-control me-3 col" label="Business Landline" InputLabelProps={{ style: { color: 'grey' }}} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBusinessDetailsChange(event, "landline")} variant="outlined" />
                                    </Box>
                                    <Box id='bankDetails'>
                                        <label htmlFor='bankDetails' style={{ fontSize: 20, fontWeight: 'bold', marginTop: 25}}>Bank Details </label>
                                        <label htmlFor='bankDetails' style={{ fontSize: 16, fontWeight: 'normal', fontStyle: 'italic', marginTop: 25}}> (Optional)</label>              
                                    </Box>
                                    <Box id='bankDetails' className="form-group-box row">
                                        <TextField id="outlined-controlled" className="form-control me-3 col" label="Bank Name" InputLabelProps={{ style: { color: 'grey' }}} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "bank_name")} variant="outlined" />
                                        <TextField id="outlined-controlled" className="form-control me-3 col" label="Account Holder Name" InputLabelProps={{ style: { color: 'grey' }}} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "account_holder_name")} variant="outlined" />
                                    </Box>
                                    <Box id='bankDetails' className="form-group-box row">
                                        <TextField id="outlined-controlled" className="form-control me-3 col" label="Account Number" InputLabelProps={{ style: { color: 'grey' }}} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "account_number")} variant="outlined" />
                                        <TextField id="outlined-controlled" className="form-control me-3 col" label="BSB/IFSC" InputLabelProps={{ style: { color: 'grey' }}} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "bsb")} variant="outlined" />
                                    </Box>

                                    <Box id="outlined-controlled" className="form-group-box row">
                                        <TextField id="outlined-controlled" className="form-control me-3 col" label="Routing Number" InputLabelProps={{ style: { color: 'grey' }}} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "routing_number")} variant="outlined" />
                                        <TextField id="outlined-controlled" className="form-control me-3 col" label="Swift Code" InputLabelProps={{ style: { color: 'grey' }}} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "swift_code")} variant="outlined" />
                                    </Box>

                                    <Box id="outlined-controlled" className="form-group-box row">
                                        <TextField id="outlined-controlled" className="form-control me-3 col" label="Iban" InputLabelProps={{ style: { color: 'grey' }}} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "iban")} variant="outlined" />
                                        <TextField id="outlined-controlled" className="form-control me-3 col" label="Branch Name" InputLabelProps={{ style: { color: 'grey' }}} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "branch_name")} variant="outlined" />
                                    </Box>

                                    <Box id="outlined-controlled" className="form-group-box">
                                        <TextField id="outlined-controlled" className="form-control me-3 col" label="Bank Address" InputLabelProps={{ style: { color: 'grey' }}} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "address")} variant="outlined" />
                                    </Box>

                                    <Box id="outlined-controlled" className="form-group-box row">
                                        <TextField id="outlined-controlled" className="form-control me-3 col" label="Pay Id Email" InputLabelProps={{ style: { color: 'grey' }}} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "pay_id_email")} variant="outlined" />
                                        <TextField id="outlined-controlled" className="form-control me-3 col" label="Pay Id Mobile" InputLabelProps={{ style: { color: 'grey' }}} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "pay_id_mobile")} variant="outlined" />
                                    </Box>

                                    <Box id="outlined-controlled" className="form-group-box row">
                                        <TextField id="outlined-controlled" className="form-control me-3 col" label="UPI Payment Id" InputLabelProps={{ style: { color: 'grey' }}} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "upi_payment_id")} variant="outlined" />
                                        <TextField id="outlined-controlled" className="form-control me-3 col" label="Pay Id Other / UPI Id" InputLabelProps={{ style: { color: 'grey' }}} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBankDetailsChange(event, "pay_id_other")} variant="outlined" />
                                    </Box>


                                    <Box id='taxDetails'>
                                        <label htmlFor='taxDetails' style={{ fontSize: 20, fontWeight: 'bold', marginTop: 25 }}>Tax Details</label>
                                        <label htmlFor='taxDetails' style={{ fontSize: 16, fontWeight: 'normal', fontStyle: 'italic', marginTop: 25}}> (Optional)</label>
                                    </Box>
                                    <Box id='taxDetails' className="form-group-box row">
                                        <TextField 
                                        id="outlined-controlled" 
                                        className="form-control me-3 col" 
                                        label="Standard Tax Rate" 
                                        value={taxDetails.category_one}
                                        InputLabelProps={{ style: { color: 'grey' }}} 
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleTaxDetailsChange(event, "category_one")} 
                                        variant="outlined" />
                                        <TextField id="outlined-controlled" className="form-control me-3 col" label="In Percent" InputLabelProps={{ style: { color: 'grey' }}} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleTaxDetailsChange(event, "value_one")} variant="outlined" />
                                    </Box>
                                    <Box id='taxDetails' className="form-group-box row">
                                        <TextField 
                                            id="outlined-controlled" 
                                            className="form-control me-3 col" 
                                            label="Alternative Tax Rate" 
                                            value={taxDetails.category_two}
                                            InputLabelProps={{ style: { color: 'grey' }}} 
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleTaxDetailsChange(event, "category_two")} 
                                            variant="outlined" />
                                        <TextField id="outlined-controlled" className="form-control me-3 col" label="In Percent" InputLabelProps={{ style: { color: 'grey' }}} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleTaxDetailsChange(event, "value_two")} variant="outlined" />
                                    </Box>
                                    <Box id='registrationDetails'>
                                        <label htmlFor='registrationDetails' style={{ fontSize: 20, fontWeight: 'bold', marginTop: 25 }}>Business Registration Details</label>
                                        <label htmlFor='registrationDetails' style={{ fontSize: 16, fontWeight: 'normal', fontStyle: 'italic', marginTop: 25}}> (Optional)</label>
                                    </Box>
                                    <Box id='registrationDetails' className="form-group-box row">
                                        <TextField id="outlined-controlled" className="form-control me-3 col" label="Registration Number / ABN" InputLabelProps={{ style: { color: 'grey' }}} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleRegistrationDetailsChange(event, "company_number")} variant="outlined" />
                                        <TextField id="outlined-controlled" className="form-control me-3 col" label="Additional Company Information" InputLabelProps={{ style: { color: 'grey' }}} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleRegistrationDetailsChange(event, "company_other_details")} variant="outlined" />
                                    </Box>
                                    <Box id='captchCheckBox' style={{ padding: '10px', border: '0px solid #ddd', borderRadius: '5px', display: 'inline-block', marginTop: '20px' }}>
                                        <label style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold'}}>
                                            <input
                                                type="checkbox"
                                                checked={isCaptchaChecked}
                                                onChange={handleCaptchaChange}
                                                style={{ marginRight: '10px' }}
                                            />
                                            <span style={{ fontSize: '20px' }}>I'm not a robot</span>
                                        </label>
                                    </Box>       
                                    <Box id="button-container" className="button-container" style={{ flex: 1, justifyContent: 'center', marginTop: '25px', marginBottom: '25px', paddingBottom: '25px' }} >
                                        <Button  type="submit" variant="contained" color="success" style={{ width: '50%', marginRight: '10px', borderRadius: '36px' }} size="large">
                                            Enrol
                                        </Button>
                                        <Button type="reset" variant="outlined" color="error" style={{ width: '50%', marginLeft: '10px', borderRadius: '36px' }} size="large">
                                            Clear
                                        </Button>
                                    </Box>
                                </Stack>
                            </Grid>
                        </Grid>
                
                    </Paper>
                </form>
            </Container>
            <Footer/>
            </ThemeProvider>
    )
}

export default EnrollYourBusiness;